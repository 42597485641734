//
// locale_ja.js
// automatically generated by generateLocalePacks.js
//
import messages from '../../app/javascript/mastodon/locales/ja.json';
import localeData from "react-intl/locale-data/ja.js";
import { setLocale } from '../../app/javascript/mastodon/locales';
import dateFnsLocaleDate from "date-fns/locale/ja/index.js";
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import setDefaultOptions from 'date-fns/setDefaultOptions';
setDefaultOptions({ locale: dateFnsLocaleDate });
registerLocale('ja', dateFnsLocaleDate);
setDefaultLocale('ja');
setLocale({messages, localeData});
